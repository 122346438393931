function CsvProcess() {
    let store = [];
    let instance = null;

    // CsvProcess.getInstance = function() {
    //     if (!instance) {
    //         instance = new CsvProcess();
    //     }
    //     return instance;
    // }

    this.getStore = function() {
        return store;
    }

    this.setStore = function(s) {
        store = s;
    }

    this.setProcess = function(obj) {
        if (store.filter(item => item.processId == obj.processId).length === 0) {
            store.unshift(obj);
            localStorage.setItem('csvFilesProgress', JSON.stringify(store));
        }
    }

    this.getProcessIdByAction = function (action) {
        let process = store.filter(item => item.actionType === action)[0];
        return process && process.processId || undefined;
    }

    this.checkIsProcessHasLink = function(processId) {
        let process = store.filter(item =>  item.processId === processId && item.hasLink)[0];
        return process && process.processId || undefined;
    }

    this.removeProcess = function(processId) {
        store = store.filter(item => item.processId != processId);
        localStorage.setItem('csvFilesProgress', JSON.stringify(store));
    }

    this.processHasLink = function (actionType) {
        store = store.map(item => {
            if (item.actionType === actionType) {
                return {...item, hasLink: true}
            }
            return  item
        });
        localStorage.setItem('csvFilesProgress', JSON.stringify(store));

    }

    this.setCanceled = function(processId) {
        store.forEach(item => item.processId == processId? item.canceled = true : item.canceled = false);
        localStorage.setItem('csvFilesProgress', JSON.stringify(store));
    }

}

window.CsvProcess = window.CsvProcess || new CsvProcess();

window.CsvProcess.setStore(JSON.parse(localStorage.getItem('csvFilesProgress')) || []);