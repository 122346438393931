import React from 'react'
import { useHistory } from 'react-router';
import { Layout, Dropdown, Modal, Button, Space } from 'antd';
import "./style.sass";
import LogoutIcon from "@components/logoutIcon";
import {
    logoutUser
} from "@actions/userActions";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
import {EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { useState } from 'react';
import { useEffect } from 'react';


const { confirm } = Modal;

function UserAccount({intl, user, logoutUser}) {
    const [userPopup, setUserPopup] = useState('');
    const history = useHistory();

    useEffect(() => {
        if (user) {
            setUserPopup(
                <Space direction="vertical" className="drop-down" size={[0, 16]}>
                    <div className="user-info">
                        <img src={user?.profile?.imageUrl || UserEmptyImg} />
                        <div className="detals">
                            <h4>{user?.profile?.FirstName} {user?.profile?.LastName}</h4>
                            <h5>{user?.profile?.email}</h5>
                        </div>
                    </div>
                    <Button icon={<EditOutlined />} onClick={() => window.location =`${process.env.CORE_URL}/edit-profile`}>
                        <FormattedMessage id="edit_profile" />
                    </Button>
                    <Button icon={<LogoutIcon />} onClick={showLogoutConfirm}>
                        <FormattedMessage id="sign_out" />
                    </Button>
                </Space>
            );
        } else {
            setUserPopup('');
        }
        console.log(user)
    }, [user])

    function showLogoutConfirm() {
        confirm({
            title: <span>
                <LogoutIcon />
            </span>,
            icon: false,
            content: intl.formatMessage({id: 'are_you_sure_you_want_to_sign_out'}),
            okText: intl.formatMessage({id: `sign_out`}),
            cancelText: intl.formatMessage({id: `cancel`}),
            okType: 'primary',
            className: 'sinout-popup',
            width: 440,
            onOk() {
                logoutUser();
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    return (
        <div>
            {/* <div className="logout">
                <img src={logoutIcon} alt="icon" className="icon" />
            </div> */}

            <Dropdown overlay={userPopup} placement="bottomRight" trigger="click">
                <div className="user-acount-button">
                    <p className="logout-text">
                        <span>
                            {user?.profile?.FirstName} {user?.profile?.LastName}
                        </span>
                    </p>
                    <img src={user?.imageUrl || UserEmptyImg} />
                </div>
            </Dropdown>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    logoutUser
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserAccount));