import {
    REQUEST_TRANSACTIONS_LIST,
    REQUEST_TRANSACTIONS_FILTER,
    REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
    REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD
} from "@actions/actionTypes";

export function getTransactionsList(params) {
    return dispatch => {
        dispatch({
            type: REQUEST_TRANSACTIONS_LIST,
            params
        })
    }
}

export function getTransactionsFilter() {
    return dispatch => {
        dispatch({
            type: REQUEST_TRANSACTIONS_FILTER
        })
    }
}

export function getTransactionsCsvLink(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_TRANSACTIONS_CSV_DOWNLOADER_URL,
            data
        })
    }
}

export function cancelTransactionCsvDownload() {
    return dispatch => {
        dispatch({
            type: REQUEST_CANCEL_TRANSACTIONS_CSV_DOWNLOAD
        })
    }
}

